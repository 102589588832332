import type {
  NotificationUserService,
  NotificationUserSettingsResponse,
  Notification
} from '@/lib/api'
import { NotificationService } from '@/lib/api'

export interface FilterSet {
  [NotificationFilterTypes.type]?: string
  [NotificationFilterTypes.date]?: string
  [NotificationFilterTypes.cameraId]?: string
}
export interface NotificationState {
  service: NotificationService
  userService: NotificationUserService
  notifications: {
    [NotificationDisplayTypes.ALL]: Array<Notification>
    [NotificationDisplayTypes.CLEARED]: Array<Notification>
  }
  pageNumbers: {
    [NotificationDisplayTypes.ALL]: number
    [NotificationDisplayTypes.CLEARED]: number
  }
  filters: {
    [NotificationDisplayTypes.ALL]: FilterSet
    [NotificationDisplayTypes.CLEARED]: FilterSet
  }
  limit: number
  offset: number
  offsetUser: number
  isLastPage: boolean
  isLastPageInUser: boolean
  userSettings: Array<NotifierUserSettingsMapped>
}

export interface NotifierUserSettingsMapped extends NotificationUserSettingsResponse {
  notificationGroup: FilterSet
}

export enum NotificationDisplayTypes {
  ALL = 'all',
  CLEARED = 'cleared'
}

export enum NotificationFilterTypes {
  type = 'type',
  date = 'date',
  cameraId = 'cameraId'
}
