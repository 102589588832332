import { NotificationNames } from '@/lib/api'

export interface Notification {
  id: string
  userId: string
  workspaceId: string
  type: string
  priority: string
  title: string
  content: string
  code: string
  notifiableId: string
  notifiableType: string
  readAt: Date
  createdAt: Date
  clearedAt: Date
  action: any
}

export enum NotificationColor {
  red = 're',
  babyBlue = 'bb',
  turquoise = 'tu',
  green = 'gr',
  yellow = 'ye'
}

export enum NotifierResourceTypes {
  Camera = 'c',
  CameraGroup = 'cg',
  Workspace = 'w',
  Map = 'm',
  Bridge = 'br',
  BridgeCamera = 'br-c',
  User = 'u',
  Bookmark = 'b',
  Team = 't'
}

export interface NotificationFindDto {
  read?: boolean
  clear?: boolean
  name?: NotificationNames[]
  resourceId?: string
  resourceType?: NotifierResourceTypes
  before?: string
  after?: string
}
