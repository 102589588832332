import type {
  CreateCustomerSettingData,
  CreateCustomerSettingResponse,
  FindCustomSetting
} from '@/lib/api'
import { Service, CustomSettingsType } from '@/lib/api'

export class CustomSettingService extends Service {
  // ***** TOUR APIS  *****
  public create(data: CreateCustomerSettingData) {
    return this.post<CreateCustomerSettingResponse>(`/api/custom-settings/custom-settings`, data)
  }
  public list(type: CustomSettingsType = CustomSettingsType.user) {
    return this.get<CreateCustomerSettingResponse[]>(`/api/custom-settings/custom-settings/${type}`)
  }
  public find<T = any>(data: FindCustomSetting) {
    return this.post<CreateCustomerSettingResponse<T>[]>(
      `/api/custom-settings/custom-settings/${data.type}/find`,
      data
    )
  }
  public update(id: string, data: CreateCustomerSettingData) {
    return this.patch<CreateCustomerSettingResponse>(
      `/api/custom-settings/custom-settings/${id}`,
      data
    )
  }

  public createOrUpdateByKey(id: FindCustomSetting, data: CreateCustomerSettingData) {
    return this.patch<CreateCustomerSettingResponse>(
      `/api/custom-settings/custom-settings/${id.type}/key/${id.key}`,
      data
    )
  }

  public deleteByKey(id: FindCustomSetting) {
    return this.delete<CreateCustomerSettingResponse>(
      `/api/custom-settings/custom-settings/${id.type}/key/${id.key}`
    )
  }

  public remove(id: string) {
    return this.delete<void>(`/api/custom-settings/custom-settings/${id}`)
  }
}
