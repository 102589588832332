const groupSettings = {
  titles: {
    aiEvents: 'AI & Video Intelligence Suite',
    other: 'Other',
    others: 'Other', // NOTICE: Backend work with "others" and front works with "other" so we need both keys
    payment: 'Billing & Invoicing',
    camera: 'Camera & System Health',
    security: 'Security'
  },
  switchTexts: {
    on: 'Always',
    off: 'Never'
  },
  FIREBASE: 'In-App Notifications',
  EMAIL: 'Email Notifications',
  SMS: 'SMS Alerts'
}
export default {
  routeName: 'notifications',
  subTabTitle: 'Inbox',
  myInbox: 'My Inbox',
  title: 'Notifications',
  notifications: 'Notifications',
  notification: 'Notification',
  clickhere: 'click here.',
  LandonSecurity: 'Show me',
  Clips: 'Show me',
  LogIn: 'Show me',
  UserDetails: 'Show me',
  PeopleLanding: 'Show me',
  RoleDetails: 'Show me',
  camera: 'Show me',
  WorkspaceBillingLanding: 'Show me',
  emailWillSend: 'All notifications will be sent to',
  new: 'New',
  muteInterval: 'Cooldown Notifications?',
  muteIntervalTooltips:
    "Notification cooldown adds a brief delay to confirm if a threat is real before sending an alert. If the event continues, you'll still be notified—perfect for low-bandwidth or unstable connections.",
  inAppNotificationHelpTooltips:
    "Stay connected and never miss a beat! Enable this to receive all your in-app, desktop and push notifications—it's the ultimate way to stay in the loop",
  securityNotificationsTooltip:
    'Unavoidable security alert categories for a seamless, reliable, and safe experience include notifications for device tampering and valuable object monitoring.',
  otherNotificationsTooltip:
    'Always stay in the know about your most important account activity! Get updates on logins, permission invites, clip downloads, and other must-have essentials for a worry-free experience.',
  groupSettings,
  dropdown: {
    /*
    Using this enum for translation
    enum ScheduleMode {
      afterHours,
      inHours,
      all
    }
    */
    labels: {
      2: 'All Day, Everyday',
      1: 'Business hours',
      0: 'After hours'
    },
    descriptions: {
      2: 'Ideal for environments demanding uninterrupted 24/7 monitoring and alerts, ensuring constant vigilance and security.',
      1: 'Designed for industries that necessitate proactive AI alerts during operational hours, facilitating critical activities such as monitoring safety zones.',
      0: 'Ideal for businesses that want to be notified of motion, intrusions, and theft after operating hours when no one is on-site.'
    },
    footerButton: 'My hours',
    tooltip: 'My preferred schedule\nfor receiving proactive\nalerts',

    focus: {
      all: {
        label: 'All-Inclusive',
        description:
          'Stay in the loop with notifications from both ArcadianAI’s Cloud AI and your camera’s hardware analytics. This option ensures you never miss a beat, combining the strengths of both technologies for complete security coverage.'
      },
      sw: {
        label: 'Cloud AI Focus',
        description:
          'Got outdated hardware flooding you with false alerts? This option’s for you! Let ArcadianAI’s state-of-the-art Cloud AI take over, delivering ultra-accurate insights so you can skip the noise and stay focused on what matters.'
      },
      hw: {
        label: 'Hardware Only',
        description:
          "Rely solely on your camera's built-in hardware analytics. Perfect for devices with robust, on-premise features already configured to your needs."
      }
    }
  },
  description: {
    title: 'My Inbox',
    text: "Everything you've snoozed, all in one place."
  },
  actions: {
    goToInbox: 'My Inbox',
    clear: 'Clear',
    verifyNewEmail: 'Verify New Email'
  },
  fastFilters: {
    all: 'All',
    ...groupSettings.titles
  },
  emptyDrawer: {
    dearUser: 'hey {fullname}',
    content:
      "Inbox Zero! You've cleared all your important notifications. In the meantime, learn about our business advocacy initiative.",
    bringFriendEarnCash: 'Bring a Friend, Earn Cash 💸'
  }
}
