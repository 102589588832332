import type { PlayerCore } from '@/player/lib/player/player-core'

export class DigitalZoomController {
  protected readonly zoomFactor = 0.25
  private readonly onMouseMoveBound: (e: MouseEvent | TouchEvent) => void
  private readonly onMouseUpBound: () => void
  private lastTouch?: { x: number; y: number }

  constructor(protected readonly core: PlayerCore) {
    this.onMouseMoveBound = this.onMouseMove.bind(this)
    this.onMouseUpBound = this.onMouseUp.bind(this)
  }

  zoomIn() {
    if (this.core.zoomConfig.enabled) {
      const wrapperEl = this.core.rootEl
      if (wrapperEl) {
        this.core.zoomConfig.level += this.zoomFactor
      }
    }
  }

  zoomOut() {
    if (this.core.zoomConfig.enabled) {
      const wrapperEl = this.core.rootEl
      if (wrapperEl && this.core.zoomConfig.level > 1) {
        this.core.zoomConfig.level -= this.zoomFactor
        if (this.core.zoomConfig.level === 1) {
          this.core.zoomConfig.origin.x = 0.5
          this.core.zoomConfig.origin.y = 0.5
        }
      } else {
        this.disable()
      }
    }
  }

  disable() {
    const wrapperEl = this.core.rootEl
    if (wrapperEl) {
      this.core.zoomConfig.origin.x = 0.5
      this.core.zoomConfig.origin.y = 0.5
    }
    this.core.zoomConfig.level = 1
    this.core.zoomConfig.enabled = false
    this.onMouseUp()
  }

  enable() {
    const wrapperEl = this.core.rootEl
    if (wrapperEl) {
      this.core.zoomConfig.origin.x = 0.5
      this.core.zoomConfig.origin.y = 0.5
    }
    this.core.zoomConfig.level = 1 + this.zoomFactor
    this.core.zoomConfig.enabled = true
  }

  protected onMouseMove(e: MouseEvent | TouchEvent) {
    let deltaX = 0
    let deltaY = 0

    if (e instanceof MouseEvent) {
      deltaX = e.movementX
      deltaY = e.movementY
    } else if (e instanceof TouchEvent && e.touches.length > 0) {
      if (!this.lastTouch) {
        this.lastTouch = { x: e.touches[0].clientX, y: e.touches[0].clientY }
        return
      }
      deltaX = e.touches[0].clientX - this.lastTouch.x
      deltaY = e.touches[0].clientY - this.lastTouch.y
      this.lastTouch = { x: e.touches[0].clientX, y: e.touches[0].clientY }
    }

    if (deltaX !== 0 || deltaY !== 0) {
      this.core.zoomConfig.origin.x -=
        deltaX / this.core.size.renderWidth / this.core.zoomConfig.level
      this.core.zoomConfig.origin.y -=
        deltaY / this.core.size.renderHeight / this.core.zoomConfig.level
      this.core.zoomConfig.origin.x = Math.min(1, Math.max(0, this.core.zoomConfig.origin.x))
      this.core.zoomConfig.origin.y = Math.min(1, Math.max(0, this.core.zoomConfig.origin.y))
    }
  }

  protected onMouseUp() {
    window.removeEventListener('touchmove', this.onMouseMoveBound)
    window.removeEventListener('mousemove', this.onMouseMoveBound)
    window.removeEventListener('mouseup', this.onMouseMoveBound)
    window.removeEventListener('touchend', this.onMouseUpBound)
    this.core.rootEl.style.cursor = 'auto'
    this.lastTouch = undefined
  }

  startDrag() {
    window.addEventListener('touchmove', this.onMouseMoveBound, { passive: true })
    window.addEventListener('touchend', this.onMouseUpBound, { passive: true })
    window.addEventListener('mousemove', this.onMouseMoveBound, { passive: true })
    window.addEventListener('mouseup', this.onMouseUpBound, { passive: true })
    this.core.rootEl.style.cursor = 'move'
  }
}
