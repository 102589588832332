import { VirtualAuthoritiesHandler } from './VirtualAuthorities'
import { AuthorityGroupHandler } from './VirtualAuthoritiesGroups'
import type { Authorities, Team, VirtualAuthorities, VirtualGroupedAuthorities } from '../interface'
import type { VirtualTeam } from './interface'

const authoritiesHandler: VirtualAuthoritiesHandler = new VirtualAuthoritiesHandler()
const groupsHandler: AuthorityGroupHandler = new AuthorityGroupHandler()

export class VirtualTeamAuthorities {
  private teams: Team[] = []
  private virtualTeams: VirtualTeam[] = []

  setTeams(teamsList: Team[]) {
    this.teams = teamsList
    this.teams.sort((a, b) => b.authorities.length - a.authorities.length)
    this.virtualTeams = []
  }

  parse(): void {
    this.teams.forEach((team) => {
      authoritiesHandler.cleanup()
      if (team.authorities) {
        const virtualAuthority = this.parseAuthorities(team.authorities)
        const groupedAthorities = this.groupingAuthorities(virtualAuthority)
        const virtualTeam: VirtualTeam = {
          name: team.name,
          description: team.description,
          authorities: groupedAthorities
        }
        this.virtualTeams.push(virtualTeam)
      }
    })
  }

  parseAuthorities(authorities: Authorities[]): VirtualAuthorities[] {
    authoritiesHandler.setTeamAuthority(authorities)
    authoritiesHandler.parser()
    return authoritiesHandler.getVirtualAuthority()
  }

  groupingAuthorities(virtualAuthorityList: VirtualAuthorities[]): VirtualGroupedAuthorities[] {
    groupsHandler.setAuthorities(virtualAuthorityList)
    groupsHandler.group()
    return groupsHandler.getGroupedAuthorities()
  }
  getVirtualTeams(): VirtualTeam[] {
    return this.virtualTeams
  }
}
